import React, { useContext } from "react";
import Form from "../AppComponents/Form";
import Context from "../Context";
import { navigate } from "gatsby";

export default () => {
  const { user } = useContext(Context);

  return (
    <>{user.isLoggedIn ? navigate("/app/tasks/") : <Form form="login" />}</>
  );
};
