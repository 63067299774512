import React, { useContext,  useEffect, useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import Context from "../Context";
import TasksLoading from "./TaskLoading";

export default () => {
  const [loading, setLoading] = useState(true);
  const [totale, setTotale] = useState(0);

  const { tasks, dispatch, user } = useContext(Context);

  const fetchTasks = async () => {
    try {
      const { data } = await axios.get(`${process.env.GATSBY_API}/post/all`
      );

      await dispatch({ type: "FETCH_TASKS", payload: data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTasks();
  }, []);
  const Calcola = () => {
    let ara: number[] = [];

    tasks.length > 1 &&
      tasks.forEach((element) => {
        let num = element.title.match(/\d+/g);

        ara.push(num != null && Number(num[0]));
      });

    setTotale(
      ara
        .filter(function (value) {
          return value;
        })
        .reduce((a, b) => a + b, 0)
    );
  };

  return (
    <>
      <div className="container">
        <h1>Totale:&nbsp;{totale > 0 && totale + "💸"}</h1>
        <h1>Tasks</h1>
        <button onClick={Calcola}>calcola totale</button>
        {loading ? (
          <TasksLoading />
        ) : (
          <>
            {tasks && (
              <ul>
                {tasks.map(({ title, _id, isDone }) => (
                  <li key={_id}>
                    <Link
                      to={`/app/task/${_id}`}
                      style={{
                        textDecoration: isDone ? "line-through" : "unset",
                      }}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            <Link to="/app/task/new/">Add new Task</Link>
          </>
        )}
      </div>
    </>
  );
};
