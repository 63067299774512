import React, { useEffect } from "react";
import App from "../components/AppComponents/App";
import Tasks from "../components/AppComponents/Tasks";
import AddTask from "../components/AppComponents/AddTask";
import Register from "../components/AppComponents/Register";
import Login from "../components/AppComponents/Login";
import { Router } from "@reach/router";
import Task from "../components/AppComponents/Task";

export default () => {
  return (
    <Router>
      <App path="/app/" component={App} />
      <Tasks path="/app/tasks/" component={Tasks} />
      <Register path="/app/register/" component={Register} />
      <Login path="/app/login/" component={Login} />
      <Task path="/app/task/:id" component={Task} />
      <AddTask path="/app/task/new" component={AddTask} />
    </Router>
  );
};
